<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1"> {{ title }} </b-card-title>
        <!-- <b-card-sub-title>Commercial networks</b-card-sub-title> -->
      </div>
    </b-card-header>

    <b-card-body>
      <!-- type="area" -->
      <vue-apex-charts
        height="300"
        :options="updatedChartOptions"
        :series="data.datos"
      />
    </b-card-body>
  </b-card>
</template>
  
<script>
const chartColors = {
  area: {
    series1: "#7367f0",
    series2: "#de8d4c",
    series3: "#00cfe8",
    series4: "#28c76f",
  },
};
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    updatedChartOptions() {
      return Object.assign({}, this.chartOptions, {
        xaxis: {
          categories: this.data.categorias,
        },
      });
    },
  },
  data() {
    return {
      rangePicker: ["2019-05-01", "2019-05-10"],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return "Bs. " + value;
          },
        },
        stroke: {
          // show: false,
          curve: "smooth",
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          fontSize: "14px",
          fontFamily: "Montserrat",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "Bs. " + value;
            },
          },
        },
        fill: {
          opacity: 1,
          type: "solid",
        },
        tooltip: {
          shared: false,
        },
        colors: [
          chartColors.area.series1,
          chartColors.area.series2,
          chartColors.area.series3,
          chartColors.area.series4,
        ],
      },
    };
  },
};
</script>
  