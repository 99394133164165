<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-card
          class="card-transaction cursor-pointer"
          @click="openModal('existencia')"
          no-body
        >
          <b-card-header>
            <b-card-title>Existencia</b-card-title>
          </b-card-header>
          <b-card-body id="knowledge-base-content">
            <statistic-card-vertical
              icon="GridIcon"
              statistic="EXISTENCIAS"
              :size="'70'"
              statistic-title="Consulta de Existencia Minima"
              color="info"
            />
            <b-card class="text-center cursor-pointer" img-top> </b-card>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card
          class="card-transaction text-center cursor-pointer"
          @click="openModal('vencimientos')"
          no-body
        >
          <b-card-header>
            <b-card-title>Vencimientos </b-card-title>
          </b-card-header>
          <b-card-body id="knowledge-base-content">
            <statistic-card-vertical
              icon="CalendarIcon"
              statistic="VENCIMIENTOS"
              :size="'70'"
              statistic-title="Consulta de Fechas de Vencimientos"
              color="primary"
            />
            <b-card class="text-center cursor-pointer" img-top> </b-card>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-from-existencia-vencimientos"
      ref="modal"
      :title="tipoModal === 'vencimientos' ? 'Vencimientos' : 'Existencias'"
      v-model="modalShow"
      no-close-on-backdrop
      no-close-on-esc
      variant="dark"
      button-size="sm"
      size="xl"
      hide-footer
    >
      <template v-if="tipoModal === 'vencimientos'">
        <VencimientoLayout :dashboard="true" />
      </template>
      <template v-else-if="tipoModal === 'existencia'">
        <ExistenciaLayout :dashboard="true" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import VencimientoLayout from "@/modules/reportes/inventario/layouts/VencimientoLayout.vue";
import ExistenciaLayout from "@/modules/reportes/inventario/layouts/ExistenciaLayout.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

import {
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    VencimientoLayout,
    ExistenciaLayout,
    StatisticCardVertical,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    anioMes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalShow: false,
      tipoModal: "", // Variable para determinar el tipo de modal
      img: require("@/assets/images/pages/calendar-illustration.png"),
    };
  },
  methods: {
    openModal(tipo) {
      this.tipoModal = tipo;
      this.modalShow = true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
#knowledge-base-content {
  .media {
    display: contents;
    align-items: flex-start;
  }
}
</style>