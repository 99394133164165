<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Transacciones</b-card-title>
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown hidden"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#"> Últimos 28 Dias </b-dropdown-item>
        <b-dropdown-item href="#"> Último Mes </b-dropdown-item>
        <b-dropdown-item href="#"> Ultimo Año </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body>
      <div class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <feather-icon size="18" icon="PocketIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">TOTAL COMPRAS</h6>
            <small>{{ anioMes }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder h4 text-dark">
          Bs. {{ data.total_compra }}
        </div>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-warning">
              <feather-icon size="18" icon="CheckIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">TOTAL VENTAS</h6>
            <small>{{ anioMes }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder h4 text-dark">
          Bs. {{ data.total_venta }}
        </div>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <feather-icon size="18" icon="DollarSignIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">TOTAL PEDIDOS</h6>
            <small>{{ anioMes }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder h4 text-dark">
          Bs. {{ data.total_pedido }}
        </div>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-success">
              <feather-icon size="18" icon="CreditCardIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">TOTAL COTIZACIONES</h6>
            <small>{{ anioMes }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder h4 text-dark">
          Bs. {{ data.total_cotizacion }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    anioMes: {
      type: String,
      default: "",
    },
  },
};
</script>
  