<template>
  <div>
    <b-card title="Bienvenido 🚀">
      <b-row>
        <b-col cols="12" lg="3">
          <b-card-text>
            {{ mixing.config.nombre_empresa }} - SIESAM- Sistema de Gestion
            Comercial</b-card-text
          >
        </b-col>
        <b-col cols="12" lg="9">
          <b-card-text v-if="$hascan('dashboard-ver-sede')">
            <b-form-radio-group
              v-model="selected"
              :options="options"
              class="text-right"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="match-height">
      <b-col xl="12" md="12">
        <DashboardTransaccionesDia :data="transaccionUsuarioDia" />
      </b-col>
    </b-row>

    <div class="">
      <b-row class="d-flex">
        <b-col cols="12" xl="6" md="6" class="d-flex">
          <DashboardTransaccionesMeses
            class="flex-fill"
            :data="transaccionUsuarioMes"
            :anioMes="anioMes"
          />
        </b-col>
        <b-col cols="12" xl="6" md="6" class="d-flex">
          <DashboardTransaccionesLotesVencimientos
            class="flex-fill"
            :data="transaccionUsuarioMes"
            :anioMes="anioMes"
          />
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12" xl="12" md="12">
        <DashboardTransaccionesGrafico
          title="Ventas de los ultimos 7 dias"
          :data="transaccionesUltimos7Dias"
        />
      </b-col>
      <b-col cols="12" xl="12" md="12">
        <DashboardTransaccionesGrafico
          title="Ventas por los ultimos meses"
          :data="transaccionesUltimosMeses"
        />
      </b-col>
    </b-row>
    <b-row> </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormRadioGroup,
  BButton,
} from "bootstrap-vue";
import DashboardTransaccionesDia from "@/views/dashboard/components/DashboardTransaccionesDia.vue";
import DashboardTransaccionesMeses from "@/views/dashboard/components/DashboardTransaccionesMeses.vue";
import DashboardTransaccionesGrafico from "./dashboard/components/DashboardTransaccionesGrafico.vue";
import DashboardTransaccionesLotesVencimientos from "./dashboard/components/DashboardTransaccionesLotesVencimientos.vue";
import DashboardTransaccionesServices from "@/views/dashboard/services/index";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    DashboardTransaccionesDia,
    DashboardTransaccionesMeses,
    DashboardTransaccionesGrafico,
    DashboardTransaccionesLotesVencimientos,
  },
  data() {
    return {
      selected: 0,
      options: [
        { text: "Transacciones por Usuario", value: 0, disabled: false },
        { text: "Transacciones por Sede", value: 1, disabled: false },
      ],
      transaccionUsuarioDia: {},
      transaccionUsuarioMes: {},
      transaccionesUltimos7Dias: {
        categorias: [],
        datos: [],
      },
      transaccionesUltimosMeses: {
        categorias: [],
        datos: [],
      },
      anioMes: "",
    };
  },
  created() {},
  watch: {
    selected(val) {
      if (val === 0) {
        this.listar();
      } else {
        this.listar();
      }
    },
  },
  methods: {
    async listar() {
      try {
        const params = {
          sedeId: this.mixing.sede_id,
          userId: this.selected == 1 ? null : this.mixing.id,
        };
        const response =
          await DashboardTransaccionesServices.getAllTransacciones(params);
        const transaccionesDia = response.data.transaccionesDia;
        this.transaccionUsuarioDia = transaccionesDia.transaccionDia;
        this.transaccionUsuarioMes = transaccionesDia.transaccionMes;
        this.anioMes = transaccionesDia.anioMes;

        this.transaccionesUltimosMeses =
          response.data.transaccionesUltimosMeses;
        this.transaccionesUltimos7Dias =
          response.data.transaccionesUltimos7Dias;
      } catch (err) {}
    },
  },
  mounted() {
    this.listar();
  },
};
</script>


<style lang="scss">
.height-pdf {
  height: calc(var(--vh, 1vh) * 100 - 8rem);
  .pdfViewer .page > * {
    height: 100% !important;
  }
}
</style>