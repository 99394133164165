<template>
  <b-row class="match-height">
    <b-col cols="12" xl="6">
      <b-row class="match-height">
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            icon="CircleIcon"
            :statistic="`${data.cantidad_compra ? data.cantidad_compra : 0}`"
            statistic-title="Compras"
            color="info"
          />
        </b-col>
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            color="info"
            icon="ShoppingBagIcon"
            :statistic="`${data.total_compra ? data.total_compra : 0} Bs`"
            statistic-title="Total Compras"
          />
        </b-col>
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            color="danger"
            icon="CircleIcon"
            :statistic="`${data.cantidad_venta ? data.cantidad_venta : 0}`"
            statistic-title="Ventas"
          />
        </b-col>
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            color="danger"
            icon="ShoppingCartIcon"
            :statistic="`${data.total_venta ? data.total_venta : 0} Bs`"
            statistic-title="Total Ventas"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" xl="6">
      <b-row class="match-height">
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            color="success"
            icon="CircleIcon"
            :statistic="`${
              data.cantidad_cotizacion ? data.cantidad_cotizacion : 0
            }`"
            statistic-title="Cotizaciones"
          />
        </b-col>
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            hide-chart
            color="success"
            icon="FileTextIcon"
            :statistic="`${
              data.total_cotizacion ? data.total_cotizacion : 0
            } Bs`"
            statistic-title="Total Cotizaciones"
          />
        </b-col>
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            icon="CircleIcon"
            :statistic="`${data.cantidad_pedido ? data.cantidad_pedido : 0}`"
            statistic-title="Pedidos"
            color="warning"
          />
        </b-col>
        <b-col cols="6" xl="3">
          <statistic-card-vertical
            color="warning"
            icon="TruckIcon"
            :statistic="`${data.total_pedido ? data.total_pedido : 0} Bs`"
            statistic-title="Total Pedidos"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

  <script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    StatisticCardVertical,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
  